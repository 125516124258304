import { BREAKPOINTS, SCREEN_SIZE } from "../statics/Breakpoints.ts";

export const getScreenSize = (windowWidth: number = window.innerWidth): SCREEN_SIZE => {
    if(windowWidth >  BREAKPOINTS.xxl) {
        return SCREEN_SIZE.xxl;
    } else if(windowWidth >  BREAKPOINTS.xl) {
        return SCREEN_SIZE.xl;
    } else if(windowWidth >  BREAKPOINTS.xl) {
        return SCREEN_SIZE.xl;
    } else if(windowWidth >  BREAKPOINTS.lg) {
        return SCREEN_SIZE.lg;
    } else if(windowWidth >  BREAKPOINTS.md) {
        return SCREEN_SIZE.md;
    } else if(windowWidth >  BREAKPOINTS.sm) {
        return SCREEN_SIZE.sm;
    } else {
        return SCREEN_SIZE.xs;
    }
}