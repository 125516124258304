import styled from "styled-components";
import { THEME_MODE } from "../../themes/CustomThemeContext.tsx";
import { GLOABL_CONFIG } from "../../statics/GlobalConfig.ts";
import { media } from "../../statics/Breakpoints.ts";

interface NavbarThemeToggleContainerProps {
    themeMode: THEME_MODE;
}

export const NavbarContainer = styled.ul<{isScrolled?: boolean}>`
    padding: 0;
    margin: 0;
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    transition: background-color ${GLOABL_CONFIG.INTERCTION_ANIMATION_DURATION}, box-shadow ${GLOABL_CONFIG.INTERCTION_ANIMATION_DURATION};
    background-color: ${props => props.theme.colors.background.primary};
    box-shadow: ${props => props.isScrolled ? props.theme.shadow.s : 'unset'};
    padding: 2rem 0;
`

export const NavbarContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${media.xs`
        flex-direction: column;
    `}
`

export const NavbarLogoContainer = styled.div`
    font-size: 3rem;
    transition: color ${GLOABL_CONFIG.THEME_TOGGLE_DURATION};
    color: ${props => props.theme.colors.typography.primary};
`

export const LinksContainer = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 4rem;
    padding: 0;

    ${media.lg`
        column-gap: 2rem;
    `}
`

export const NavbarItem = styled.li`
    list-style: none;
    font-size: 2rem;

    a {
        text-decoration: none;
    }
`

export const NavbarThemeToggleContainer = styled.div<NavbarThemeToggleContainerProps>`
    .checkbox {
        opacity: 0;
        position: absolute;
    }

    .checkbox-label {
        background-color: ${props => props.themeMode === THEME_MODE.LIGHT ? '#DDDDDD' : '#555555'};
        width: 60px;
        height: 30px;
        border-radius: 50px;
        position: relative;
        padding: 5px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: background-color 0.2s linear;
    }

    .fa-moon {color: #f1c40f;}

    .fa-sun {color: #f39c12;}

    .checkbox-label .ball {
        background-color: #fff;
        width: 30px;
        height: 30px;
        position: absolute;
        left: 4px;
        top: 5px;
        border-radius: 50%;
        transition: transform ${GLOABL_CONFIG.THEME_TOGGLE_DURATION} linear;
    }

    .checkbox:checked + .checkbox-label .ball {
        transform: translateX(32px);
    }
`