import React from "react";
import { ANCHOR_TYPE, AnchorTag, CHIP_TYPE, Chip, HEADER_TYPE, Header, PARAGRAPH_TYPE, Paragraph } from "../../components/index.ts";
import { ProjectsStatics } from "../../statics/ContentStatics.ts";
import { ProjectItemContentWrapper, ProjectItemImageWrapper, ProjectItemWrapper, ProjectsContainer, ProjectsHeader, ProjectsListContainer, ProjectsToolsWrapper } from "./Projects.styled.ts";
import { ProjectItemType } from "../../types/Projects.types.ts";

const Projects = () => {

    const getHeaderView = () => (
        <ProjectsHeader>
            <Header type={HEADER_TYPE.H4}>{ProjectsStatics.header}</Header>
        </ProjectsHeader>
    )

    const getToolsItem = (toolItem: string, i: number) => (
        <Chip type={CHIP_TYPE.PRIMARY}>{toolItem}</Chip>
    )

    const getItemView = (itemData: ProjectItemType) => (
        <ProjectItemWrapper>
            <ProjectItemImageWrapper>
                <img src={itemData.imageUrl} alt={itemData.title} />
            </ProjectItemImageWrapper>
            <ProjectItemContentWrapper>
                <Header type={HEADER_TYPE.H4}>{itemData.title}</Header>
                <Header type={HEADER_TYPE.H6}>{itemData.subTitle}</Header>
                <Paragraph type={PARAGRAPH_TYPE.S} isDangerous>{itemData.description}</Paragraph>
                <ProjectsToolsWrapper>
                    {itemData.tools?.map(getToolsItem)}
                </ProjectsToolsWrapper>
                {itemData.link && <AnchorTag href={itemData.link} type={ANCHOR_TYPE.S}>{itemData.linkText}</AnchorTag>}
            </ProjectItemContentWrapper>
        </ProjectItemWrapper>
    )

    const getProjectsView = () => (
        <ProjectsListContainer>
            {ProjectsStatics.items.map(getItemView)}
        </ProjectsListContainer>
    )

    return (
        <ProjectsContainer>
            {getHeaderView()}
            {getProjectsView()}
        </ProjectsContainer>
    )
}

export default Projects;