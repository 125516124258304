import styled from "styled-components";
import { media } from '../../statics/Breakpoints.ts'

export const ExperiencesContainer = styled.div`
    margin: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ExperiencesHeaderContainer = styled.div`
    
`

export const ExperienceItemContainer = styled.div`
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
`

export const ExperienceItemWrapper = styled.div`
    display: flex;
    column-gap: 2rem;

    border-color: ${props => props.theme.colors.theme.primary};
    border-style: solid;
    border-width: 0;

    ${media.sm`
        flex-direction: column;
        align-items: flex-start;
        border-bottom-width: 2px;
        padding-bottom: 3rem;
    `}
`

export const ExperienceInfoContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 1rem;
    padding-right: 2rem;
    border-color: ${props => props.theme.colors.theme.secondary};
    border-style: solid;
    border-width: 0;
    border-right-width: 2px;

    ${media.sm`
        border-right-width: 0;
        border-bottom-width: 2px;
        padding-right: 0;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        align-items: flex-start;
    `}
`

export const ExperienceInfoCompanyLogoContainer = styled.div`
    padding-bottom: 2rem;
    border-bottom: ${props => `2px solid ${props.theme.colors.theme.secondary}`};
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.md`
        width: 200px;
    `}

    ${media.sm`
        border-bottom: 0;
    `}

    img {
        width: 100%;
        background-color: ${props => props.theme.colors.solids.white};
        
    }
`

export const ExperienceInfoCompanyNameAnchor = styled.a`
    text-decoration: none;
`

export const ExperiencePositionsContainer = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`

export const PositionItemWrapper = styled.div`
   display: flex;
   flex-direction: column;
   row-gap: 1rem;
`

export const PositionsTechStackContainer = styled.div`
    margin-top: 2rem;
    display: flex;
    column-gap: 1rem;
    row-gap: 1rem;
    flex-wrap: wrap;

    ${media.sm`
        margin-top: 1rem;
        column-gap: 0.5rem;
        row-gap: 0.5rem;
    `}
`

