import React from 'react';
import styled from 'styled-components';

export enum DIVIDER_TYPE {
    LIGHT = 'LIGHT',
    DARK = 'DARK',
}

type DividerProps = {
    marginVertical?: number,
    type?: DIVIDER_TYPE,
}

const Divider = (props: DividerProps) => (
    <DividerStyle type={props.type}/>
)

const DividerStyle = styled.div<{type?: DIVIDER_TYPE}>`
    height: 2px;
    width: 100%;
    background-color: ${props =>  props.type === DIVIDER_TYPE.DARK ? props.theme.colors.borders.dark : props.theme.colors.borders.light};
`

export default Divider;