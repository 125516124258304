import styled from "styled-components";
import { media } from "../../statics/Breakpoints.ts";

export const ContactContainer = styled.div`
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3rem;
`

export const ContactHeader = styled.div`

`

export const ContactItemsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 4rem;

    ${media.md`
        gap: 2rem;
    `}
`

export const ContactItemLink = styled.a`
    
`

export const ContactLocationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
`