import styled, { createGlobalStyle } from "styled-components";
import { GLOABL_CONFIG } from "./statics/GlobalConfig.ts";

export const GlobalStyle = createGlobalStyle`
    html {
        font-size: 10px;
        font-family: "Open Sans", sans-serif;
        scroll-behavior: smooth;
    }
    body {
        width: 100vw;
        height: 100vh;
        margin: 0;
        padding: 0;
        background-color: ${props => props.theme.colors.background.primary};
        transition: background-color ${GLOABL_CONFIG.THEME_TOGGLE_DURATION};
    }
    #root {
        /* padding-bottom: 5rem; */
    }
`

export const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-top: 10rem;
`