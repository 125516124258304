import { faFacebook, faInstagram, faLinkedin, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { ContactStaticsType } from "../types/Contact.types"
import { ExperienceItemsType } from "../types/Experience.types"
import { ProjectStaticsType } from "../types/Projects.types"
import { SkillsType } from "../types/Skills.types"

export const AboutStatics = {
    img: '/assets/profile-images/profile_1.png',
    header: 'Diganta<br/>Das',
    subHeader: 'React | React Native | Typescript | Javascript',
    content: `
        Passionate and highly skilled JavaScript Frontend developer with a proven track record of 6 years in a startup, 
        specializing in designing and developing cutting-edge web and mobile applications utilizing React and React Native frameworks. 
        Successfully delivered end-to-end 0projects, from inception to deployment in production environments and app stores.
        <br/><br/>
        Demonstrated expertise in micro-frontend projects, leveraging the power of Webpack Module Federation (WMF) 
        and employing component libraries to drive efficient and scalable development practices. 
        Proficient in developing hybrid mobile applications, combining the best of web and native technologies for enhanced user experiences.
    `
}

export const ExperiencesStatics: {header: string, items: ExperienceItemsType[]} = {
    header: "Experiences",
    items: [{
        id: 'plusgrade',
        comapnyName: 'Plusgrade | points',
        linkdinUrl: 'https://www.linkedin.com/company/plusgrade/mycompany/',
        companyLogo: '/assets/icons/plusgrade.png',
        startDate: new Date('07/01/2023'),
        endDate: undefined,
        jobType: 'Full-time',
        positions: [{
            title: 'Senior Software Developer',
            startDate: new Date('07/01/2023'),
            endDate: undefined,
            description: `
                <ul>
                    <li>Enhancing the React console tool with functionalities aimed at facilitating the transition of clients from the old website to the new one.</li>
                    <li>Incorporating additional utility functions utilized by other applications.</li>
                    <li>Modify Node.js backend controllers to accommodate new features.</li>
                    <li>Engaging in conversations and documenting coding standards and optimal practices across various teams.</li>
                    <li>Establish new email templates to align with the updated email framework.</li>
                    <li>Collaborate with the team to develop a new component library and integrate it into the new products.</li>
                </ul>
            `,
            techStack: ['React', 'Typescript', 'Styled Components', 'NodeJS', 'Salesforce Commerce Cloud'],
        }]
    }, {
        id: 'evive_3',
        comapnyName: 'Evive | goevive.com',
        linkdinUrl: 'https://www.linkedin.com/company/evive-/',
        companyLogo: '/assets/icons/evive.png',
        startDate: new Date('04/01/2023'),
        endDate: new Date('06/30/2023'),
        jobType: 'Contract',
        positions: [{
            title: 'Engineering Consultant - Contract',
            startDate: new Date('04/01/2023'),
            endDate: new Date('06/01/2023'),
            description: `
                <ul>
                    <li>Develop and execute a strategy to transition the architecture from the old platform to the new one, including the creation of different production deployment plans and a beta phase.                    </li>
                    <li>Provide guidance and mentorship to a team of developers as they create several microfrontend applications.</li>
                    <li>Incorporate multiple MFE applications into the hybrid React Native mobile applications.</li>
                    <li>Collect feedback and continuously enhance the new product as it reaches a broader user base.</li>
                </ul>
            `,
            techStack: ['React', 'Typescript', 'Storybook', 'Rollup', 'AWS', 'React Native', 'WMF'],
        }]
    }, {
        id: 'evive_2',
        comapnyName: 'Evive | goevive.com',
        linkdinUrl: 'https://www.linkedin.com/company/evive-/',
        companyLogo: '/assets/icons/evive.png',
        startDate: new Date('05/01/2017'),
        endDate: new Date('03/31/2023'),
        jobType: 'Full-time',
        positions: [{
            title: 'Senior Technical Lead - Frontend',
            startDate: new Date('01/01/2022'),
            endDate: new Date('03/31/2023'),
            description: `
                <ul>
                    <li>Successfully transformed the entire suite of enterprise applications into micro frontends using Webpack Module Federation, reducing development time by 50%.</li>
                    <li>Led the development and architectural direction of hybrid mobile applications using React and React Native, integrating seamlessly with web views to achieve cross-platform compatibility and scalability.</li>
                    <li>Architected an enterprise component library with React, Typescript, and Storybook.</li>
                    <li>Pioneered the establishment of a robust and automated Continuous Integration and Continuous Deployment (CICD) pipeline, leveraging GoCD and App Center. This initiative resulted in the consistent delivery of high-quality software to production environments.</li>
                    <li>Exercised leadership prowess in guiding and overseeing a proficient front-end team responsible for the development of client-facing applications. Provided mentorship and strategic direction to ensure the team's success and alignment with organizational objectives.</li>
                    <li>Enforced stringent code styling standards and best practices across all development teams and repositories, fostering a culture of excellence in code quality, readability, and maintainability.</li>
                    <li>Collaborated closely with stakeholders to elicit and refine project requirements, providing technical expertise and feedback to ensure alignment with overarching business objectives and user needs.</li>
                </ul>
            `,
            techStack: ['React', 'Typescript', 'Storybook', 'Rollup', 'AWS', 'React Native', 'WMF'],
        }, {
            title: 'Lead Software Development - Frontend',
            startDate: new Date('05/01/2018'),
            endDate: new Date('12/31/2021'),
            description: `
                <ul>
                    <li>Led the comprehensive development lifecycle of React and React Native projects, championing the implementation of industry-best coding standards and methodologies while providing mentorship and guidance to team members.</li>
                    <li>Collaborated closely with cross-functional teams to facilitate discussions and define API JSON contracts, ensuring seamless integration and interoperability across different modules and services</li>
                    <li>Successfully orchestrated the adoption of a microservice-based architecture for the frontend, enhancing scalability, modularity, and maintainability of the applications.</li>
                    <li>Engineered resilient unit test suites using Jest and other cutting-edge testing libraries, ensuring robust test coverage and adherence to quality standards.</li>
                </ul>
            `,
            techStack: ['React', 'AWS', 'React Native', 'Webpack', 'GoCD'],
        }, {
            title: 'Javascript Developer',
            startDate: new Date('05/01/2017'),
            endDate: new Date('04/30/2018'),
            description: `
                <ul>
                    <li>Actively participated in the development of a range of projects using React, React Native, Redux, SCSS.</li>
                    <li>Collaborated with designers, developers, business analysts, and other stakeholders.</li>
                    <li>Led small teams to develop new projects, with regular code reviews.</li>
                    <li>Created pixel perfect responsive UI components with cross-browser compatibility, while adhering to accessibility guidelines and web standards.</li>
                </ul>
            `,
            techStack: ['React', 'NodeJS', 'React Native', 'Webpack', 'Docker'],
        }]
    }, {
        id: 'evive_1',
        comapnyName: 'Evive | goevive.com',
        linkdinUrl: 'https://www.linkedin.com/company/evive-/',
        companyLogo: '/assets/icons/evive.png',
        startDate: new Date('11/01/2016'),
        endDate: new Date('04/30/2017'),
        jobType: 'Internship',
        positions: [{
            title: 'Javascript Developer Intern',
            startDate: new Date('11/01/2016'),
            endDate: new Date('04/30/2017'),
            description: 'Developed and maintained JavaScript applications utilizing both React JS and React Native frameworks.',
            techStack: ['Javascript', 'HTML', 'CSS', 'React Native'],
        }]
    }, {
        id: 'freelancer_1',
        comapnyName: 'Freelancer',
        linkdinUrl: '',
        companyLogo: '/assets/icons/freelancer.png',
        startDate: new Date('08/01/2015'),
        endDate: new Date('10/31/2016'),
        jobType: 'Freelancer',
        positions: [{
            title: 'Web Developer',
            startDate: new Date('08/01/2015'),
            endDate: new Date('10/31/2016'),
            description: 'Worked as a freelance web developer, using PHP, MySQL, HTML, JavaScript, Jquery and CSS. Coordinated with clients to gather requirements and specifications projects.',
            techStack: ['PHP', 'MySQL', 'Javascript', 'jQuery', 'HTML', 'CSS'],
        }]
    }]
}
export const SkillsStatics: SkillsType = {
    header: 'Skills',
    categories: [{
        id: 'frontend',
        name: 'Frontend',
        items: ['React', 'ReactNative', 'Typescript', 'SASS', 'Styled Components', 'Webpack', 'Rollup', 'JavaScript', 'HTML', 'CSS'],
    }, {
        id: 'backend',
        name: 'Backend',
        items: ['NodeJS', 'Express'],
    }, {
        id: 'services',
        name: 'Services',
        items: ['AWS', 'Google Cloud Server', 'GoCD', 'FullStory', 'Microsoft App Center', 'Google Play Store', 'Apple App Store', 'Commerce Cloud', 'Auth0'],
    }, {
        id: 'other',
        name: 'Other Exposure',
        items: ['Docker', 'SQL', 'DataDog', 'Jenkins', 'Material UI', 'Python', 'Java'],
    }, {
        id: 'hobby',
        name: 'Hobby Skills',
        items: ['Figma', 'Adobe Photoshop', 'Adobe Lightroom', 'Adobe Premiere Pro', 'Da Vinci Resolve', 'Resolume Arena'],
    }]
}

export const ProjectsStatics: ProjectStaticsType = {
    header: 'Key Projects',
    items: [{
        id: 'evive-uikit',
        imageUrl: '/assets/projects/EvUIkit.png',
        title: 'Evive UI Kit',
        subTitle: 'A custom UiKit library',
        description: `
            Led the architecture and development of an enterprise component library that streamlined the development process and ensured consistent and visually cohesive user interfaces across multiple projects. 
            Significantly reduced development time and enhanced code maintainability.
            <br/><br/>
            From the initial discussions involving marketing, design, and branding teams, I orchestrated the project from the inception of the design language phase through to implementation. 
            I facilitated the seamless migration of all projects to this new UI Library, enabling continuous updates and the release of new versions with additional components and features.
            <br/><br/>
            The process commenced with the establishment of core atomic components such as Typography, Cards, Buttons, and Color tokens, followed by the integration of larger shared components like Cards and Accordions. 
            Furthermore, I led the development of version 2 of this library, tailored specifically to support all MFE (Micro-Frontends) applications. 
            Subsequently, this library was bifurcated into two separate entities: one dedicated solely to UI components, while the other focused on supporting development tools.
        `,
        tools: ['Storybook', 'React', 'Typescript', 'Rollup', 'AWS Code Artifact', 'NPM'],
        linkText: 'Link to Evive UIKit Storybook',
        link: 'https://uikit.myevive.me/v2/index.html',
    }, {
        id: 'myevive-dashboard',
        imageUrl: '/assets/projects/EviveDashboard.png',
        title: 'MyEvive MFE',
        subTitle: 'A microfrontend application using Webpack Module Federation',
        description: 'Revamped the suite of applications by transitioning from a mono-repo architecture to a micro-frontend architecture utilizing Webpack Module Federation. This new architecture features an App Shell, which serves as the central hub for authentication and shared resources. Each independently developed, tested, and deployed application operates within this shell, ensuring a uniform user experience.<br/><br/>This restructuring has notably decreased development time, empowering each team to progress at its own pace without concerns about higher-level components such as authentication and user data. Nevertheless, for end users, the transition between applications remains seamless and consistent.<br/><br/>Each project is linked to its own dedicated CI/CD pipeline through GoCD. This setup allows for the independent deployment of each application without impacting or necessitating the redeployment of the remaining applications.',
        tools: ['Micro-Frontends', 'React', 'Typescript', 'Webpack Module Federation', 'AWS', 'GoCD'],
    }, {
        id: 'myevive-mobile-app',
        imageUrl: '/assets/projects/EviveMobileApp.png',
        title: 'MyEvive Hybrid Mobile App',
        subTitle: 'A hybrid React Native mobile application',
        description: 'Minimized development efforts by implementing the webpack microfrontends architecture within the mobile app through a hybrid method. While the primary functionalities of the mobile app were developed natively, other shared applications were integrated within the app using web views.<br/><br/>This strategy significantly reduced development time by eliminating the need to maintain separate codebases for both web and app versions of the same application. Furthermore, any updates made to the web app were automatically reflected in the mobile app without requiring additional deployments to app stores. This approach ensured a seamless user experience, seamlessly integrating web content within the app interface without the user feeling like they were navigating away to a web page.',
        tools: ['Micro-Frontends', 'React', 'Typescript', 'React-Native', 'App Center', 'Android', 'iOS'],
    }]
}

export const ContactStatics: ContactStaticsType = {
    header: 'Contact',
    email: {
        id: 'mail',
        label: 'diganta162004@gmail.com',
        link: 'mailto:diganta162004@gmail.com',
    },
    location: {
        id: 'location',
        icon: faLocationDot,
        label: 'Etobicoke, Toronto, Ontario, Canada',
        link: 'https://maps.app.goo.gl/W1HVwzdw2RZJUZqh6',
    },
    items: [{
        id: 'mail',
        icon: faEnvelope,
        link: 'mailto:diganta162004@gmail.com', 
     }, {
       id: 'facebook',
       icon: faFacebook,
       link: 'https://www.facebook.com/D2D.D2D', 
    }, {
        id: 'instagram',
        icon: faInstagram,
        link: 'https://www.instagram.com/diganta.das.d2d/', 
     }, {
        id: 'linkdin',
        icon: faLinkedin,
        link: 'https://www.linkedin.com/in/digantadas/', 
     }, {
        id: 'github',
        icon: faGithub,
        link: 'https://github.com/diganta162004', 
     }, {
        id: 'medium',
        icon: faMedium,
        link: 'https://medium.com/@diganta162004', 
     }]
}