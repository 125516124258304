import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'
import { HashLink } from 'react-router-hash-link';
import { useTheme } from "styled-components";

import { NavbarProps } from "../../types/Navbar.types.ts";
import { NavbarItem, NavbarContainer, NavbarLogoContainer, LinksContainer, NavbarThemeToggleContainer, NavbarContent } from "./Navbar.styled.ts";
import { THEME_MODE, useCustomTheme } from "../../themes/CustomThemeContext.tsx";
import { Label, PageLayout } from "../../components/index.ts";
import { RouteType } from "../../routes/routeStatics.ts";
import { useDevice } from "../../hooks/useDevice.tsx";
import { SCREEN_SIZE } from "../../statics/Breakpoints.ts";

const Navbar = (props: NavbarProps) => {
    const { value, items } = props;
    const { themeMode, updateThemeMode } = useCustomTheme();
    const [ isScrolled, setIsScrolled ] = useState(false);

    const theme = useTheme();
    const {isScreenLessThan} = useDevice();

    const onThemeToggle = useCallback((e) => {
        updateThemeMode(e.target.checked === true ? THEME_MODE.DARK : THEME_MODE.LIGHT);
    }, [updateThemeMode])

    const scrollWithOffset = useCallback((el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }, [])

    useEffect(() => {
        const onScrollHandler = (e) => {
            const scrollPosition = window.scrollY;
            if(scrollPosition < 30) {
                setIsScrolled(false);
            } else {
                setIsScrolled(true);
            }
        }
        const onScroll: any = window.addEventListener('scroll', onScrollHandler);
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const getLogoView = () => (
        <NavbarLogoContainer>
            DD
        </NavbarLogoContainer>
    )

    const getNavItem = (itemData: RouteType) => (
        <NavbarItem key={itemData.id}>
            <HashLink smooth to={itemData.route} scroll={scrollWithOffset}>
                {isScreenLessThan(SCREEN_SIZE.md)
                    ? <FontAwesomeIcon aria-label={itemData.name} icon={itemData.icon!} color={theme.colors.typography.tertiary} size="lg"/>
                    : <Label>{itemData.name.toUpperCase()}</Label>}
            </HashLink>
        </NavbarItem>
    )

    const getThemeToggleView = () => (
        <NavbarThemeToggleContainer themeMode={themeMode}>
            <input type="checkbox" className="checkbox" id="checkbox" onClick={onThemeToggle} />
            <label for="checkbox" className="checkbox-label">
                <FontAwesomeIcon size="3x" icon={faMoon} />
                <FontAwesomeIcon size="2x" icon={faSun} />
                <span className="ball" />
            </label>
        </NavbarThemeToggleContainer>
    )

    const getLinksContainer = () => (
        <LinksContainer>
            {items?.map(getNavItem)}
            {getThemeToggleView()}
        </LinksContainer>
    )

    return (
        <NavbarContainer isScrolled={isScrolled}>
            <PageLayout backgroundColor="unset">
                <NavbarContent>
                    {getLogoView()}
                    {getLinksContainer()}
                </NavbarContent>
            </PageLayout>
        </NavbarContainer>
    )
}

export default Navbar;