import React from "react";
import { LandingPageContainer } from "./LandingPage.styled.js";
import { ROUTES } from "../../routes/routeStatics.ts";
import { Divider, PageLayout } from "../../components/index.ts";
import About from "../about/About.tsx";
import Skills from "../skills/Skills.tsx";
import ExperiencesPage from "../experiencesPage/ExperiencesPage.tsx";
import Projects from "../projects/Projects.tsx";
import ContactPage from "../contact/ContactPage.tsx";

const LandingPage = () => (
    <LandingPageContainer>
        <PageLayout id={ROUTES.HOME.id}>
            <About />
        </PageLayout>
        <PageLayout id={ROUTES.SKILLS.id} isHighlight>
            <Skills />
        </PageLayout>
        <PageLayout id={ROUTES.EXPERIENCES.id}>
            <ExperiencesPage />
        </PageLayout>
        <PageLayout><Divider /></PageLayout>
        <PageLayout id={ROUTES.PROJECTS.id}>
            <Projects />
        </PageLayout>
        <PageLayout id={ROUTES.CONTACT.id} isHighlight>
            <ContactPage />
        </PageLayout>
    </LandingPageContainer>
)

export default LandingPage;