import styled from "styled-components";
import { media } from "../../statics/Breakpoints.ts";

export const AboutContainer = styled.div`
    display: flex;
    row-gap: 2rem;

    ${media.lg`
        flex-direction: column-reverse;
    `}
`

export const AboutContentContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 2rem;
`

export const AboutImageContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;

    max-height: 80rem;

    ${media.lg`
        height: 50rem;
        flex: unset;
    `}

    img {
        object-fit: contain;
    }
`