import React from "react";
import {
    BrowserRouter,
    Route,
    Routes,
} from "react-router-dom";
import LandingPage from "../features/landingPage/LandingPage.tsx";
import { ROUTES, navbarItems } from "./routeStatics.ts";
import ExperiencesPage from "../features/experiencesPage/ExperiencesPage.tsx";
import Navbar from "../features/navbar/Navbar.tsx";
import Skills from "../features/skills/Skills.tsx";

const AppRouter = () => (
    <BrowserRouter>
        <Navbar items={navbarItems}/>
        <Routes>
            <Route path="*" Component={LandingPage}/>
            {/* <Route path={ROUTES.HOME.route} Component={LandingPage}/> */}
            {/* <Route path={ROUTES.EXPERIENCES.route} Component={ExperiencesPage}/> */}
            {/* <Route path={ROUTES.SKILLS.route} Component={Skills}/> */}
        </Routes>
    </BrowserRouter>
)

export default AppRouter;