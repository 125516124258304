import React from "react";
import styled, { css } from "styled-components";

import { GLOABL_CONFIG } from "../../statics/GlobalConfig.ts";
import { media } from "../../statics/Breakpoints.ts";

export enum CHIP_TYPE {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
}

type ChipPropType = {
    children?: string,
    type?: CHIP_TYPE,
    isDangerous?: boolean,
}

const Chip = (props: ChipPropType) => {
    const { type, children, isDangerous } = props;

    switch(type) {
        case CHIP_TYPE.SECONDARY:
            return <SecondaryChip>{children?.toUpperCase()}</SecondaryChip>
        case CHIP_TYPE.PRIMARY:
        default:
            return <PrimaryChip>{children?.toUpperCase()}</PrimaryChip>
    }

}

const CommonStyles = css`
    transition: color ${GLOABL_CONFIG.THEME_TOGGLE_DURATION};
    font-weight: 700;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;

    ${media.md`
        font-size: 1.2rem;
    `}
`

const PrimaryChip = styled.span`
    ${CommonStyles}
    background-color: ${props =>  props.theme.colors.theme.primary};
    color: ${props =>  props.theme.colors.typography.inverted};
`

const SecondaryChip = styled.span`
    ${CommonStyles}
    background-color: ${props =>  props.theme.colors.theme.secondary};
    color: ${props =>  props.theme.colors.typography.inverted};
`

export default Chip;