import React from 'react';
import { ContactContainer, ContactHeader, ContactItemLink, ContactItemsContainer, ContactLocationWrapper } from './Contact.styled.ts';
import { AnchorTag, HEADER_TYPE, Header } from '../../components/index.ts';
import { ContactStatics } from '../../statics/ContentStatics.ts';
import { ContactItemType } from '../../types/Contact.types.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';

const ContactPage = () => {

    const theme = useTheme();

    const getHeaderView = () => (
        <ContactHeader>
            <Header type={HEADER_TYPE.H4}>{ContactStatics.header}</Header>
        </ContactHeader>
    )

    const getContactItem = (itemData: ContactItemType) =>
        itemData.icon && 
            <ContactItemLink 
                key={itemData.id}
                href={itemData.link}
                rel="noopener noreferrer external"
                target="_blank"
            >
                <FontAwesomeIcon 
                    aria-label={itemData.label} 
                    color={theme.colors.theme.primary} 
                    icon={itemData.icon} 
                    size={'4x'} 
                />
            </ContactItemLink>
    
    const getContactList = () => (
        <ContactItemsContainer>
            {ContactStatics.items.map(getContactItem)}
        </ContactItemsContainer>
    )

    const getEmail = () => (
        <AnchorTag href={ContactStatics.email?.link}>{ContactStatics.email?.label}</AnchorTag>
    )

    const getLocation = () => (
        <ContactLocationWrapper>
            <FontAwesomeIcon 
                icon={ContactStatics.location?.icon!}
                size='2x'
                color={theme.colors.typography.tertiary} />
            <AnchorTag
                href={ContactStatics.location?.link}
            >
                {ContactStatics.location?.label}
            </AnchorTag>
        </ContactLocationWrapper>
    )

    return (
        <ContactContainer>
            {getHeaderView()}
            {getEmail()}
            {getContactList()}
            {getLocation()}
        </ContactContainer>
    )
}

export default ContactPage;