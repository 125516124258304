import React, { ReactNode, useCallback, useContext, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { DarkTheme, LightTheme } from './themes.ts';

export enum THEME_MODE {
    LIGHT = 'LIGHT',
    DARK = 'DARK',
}

type ThemeContextType = {
    themeMode: THEME_MODE,
    updateThemeMode: (THEME_MODE) => void,
}

const defaultTheme: ThemeContextType = {
    themeMode: THEME_MODE.LIGHT,
    updateThemeMode: () => null,
}

const CustomThemeContext = React.createContext<ThemeContextType>(defaultTheme);

export const useCustomTheme = () => useContext(CustomThemeContext);

export const CustomThemeProvider = ({ children }: {children: ReactNode}) => {
    const [themeMode, setThemeMode] = useState(THEME_MODE.LIGHT)

    const updateThemeMode = useCallback((newTheme: THEME_MODE) => {
        setThemeMode(newTheme);
    }, [])
  
    return (
        <ThemeProvider theme={themeMode === THEME_MODE.DARK ? DarkTheme : LightTheme}>
            <CustomThemeContext.Provider value={{ themeMode, updateThemeMode }}>
                {children}
            </CustomThemeContext.Provider>
        </ThemeProvider>
    )
}

