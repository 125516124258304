type ThemeType = {
    colors: {
        theme: {
            primary: string,
            secondary: string,
            tertiary: string,
        }
        background: {
            primary: string,
            secondary: string,
        },
        typography: {
            primary: string,
            secondary: string,
            tertiary: string,
            desctructive: string,
            inverted: string,
        },
        solids: {
            white: string,
            black: string,
            grey: string,
        },
        borders: {
            light: string,
            dark: string,
        }
    },
    shadow: {
        m: string,
        s: string,
    }
}

export const LightTheme: ThemeType = {
    colors: {
        theme: {
            primary: '#DE6449',
            secondary: '#41D3BD',
            tertiary: '#407899',
        },
        background: {
            primary: '#FFFFFF',
            secondary: '#EEEEEE'
        },
        typography: {
            primary: '#333333',
            secondary: '#555555',
            tertiary: '#777777',
            desctructive: '#FF0000',
            inverted: '#FFFFFF',
        },
        solids: {
            white: '#FFFFFF',
            black: "#000000",
            grey: '#999999',
        },
        borders: {
            light: '#BBBBBB',
            dark: '#555555',
        }
    },
    shadow: {
        m: '4px 4px 20px #555555',
        s: '0px 0px 20px #AAAAAA'
    }
}

export const DarkTheme: ThemeType = {
    colors: {
        theme: {
            primary: '#DE6449',
            secondary: '#41D3BD',
            tertiary: '#407899',
        },
        background: {
            primary: '#222222',
            secondary: '#555555'
        },
        typography: {
            primary: '#FFFFFF',
            secondary: '#DDDDDD',
            tertiary: '#AAAAAA',
            desctructive: '#FF0000',
            inverted: '#FFFFFF',
        },
        solids: {
            white: '#FFFFFF',
            black: "#000000",
            grey: '#999999',
        },
        borders: {
            light: '#555555',
            dark: '#AAAAAA',
        }
    },
    shadow: {
        m: '0px 0px 0px #000000',
        s: '0px 0px 0px #000000'
    }
}