import styled from "styled-components";

import { GLOABL_CONFIG } from "../../statics/GlobalConfig.ts";
import { media } from "../../statics/Breakpoints.ts";

export const PageLayoutStyled = styled.div<{isHighlight?: boolean, backgroundColor?: string}>`
    padding: 0 10%;
    transition: background-color ${GLOABL_CONFIG.THEME_TOGGLE_DURATION};
    background-color: ${props => props.isHighlight ? props.theme.colors.background.secondary : props.backgroundColor || props.theme.colors.background.primary};

    ${media.md`
        padding: 0 5%;
    `}
`