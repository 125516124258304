import DateTime from 'date-and-time';

export const toDisplayMonthYear = (value?: Date) => {
    return value ? value.toLocaleString('default', { month: 'short', year: 'numeric' }) : '';
}

const getFormatedStringFromDays = (numberOfDays: number) => {
    let years = Math.floor(numberOfDays / 365);
    let months = Math.ceil(numberOfDays % 365 / 30);

    if(months >= 12) {
        years += 1;
        months = 0;
    }

    const yearsDisplay = years > 0 ? years + (years == 1 ? " year " : " years ") : "";
    const monthsDisplay = months > 0 ? months + (months == 1 ? " month" : " months") : "";
    return yearsDisplay + monthsDisplay; 
}

export const getYearsMonthsBetweenDates = (date1: Date, date2: Date) => {
    const diff = Math.abs(DateTime.subtract(date1, date2).toDays());
    return getFormatedStringFromDays(diff);
}