import React, { ElementType, ReactNode } from "react";
import styled, { css } from "styled-components";
import { GLOABL_CONFIG } from "../../statics/GlobalConfig.ts";
import { media } from "../../statics/Breakpoints.ts";

export enum PARAGRAPH_TYPE {
    L = 'L',
    M = 'M',
    S = 'S',
}

export type ParagraphProps = {
    children?: ReactNode,
    isDangerous?: boolean,
    type?: PARAGRAPH_TYPE,
}

const Paragraph = (props: ParagraphProps) => {
    const { children, isDangerous, type } = props;

    const getContent = (WrapperComponent: ElementType) => {
        if(isDangerous) {
            return <WrapperComponent dangerouslySetInnerHTML={{__html: children}}/>
        }
        return <WrapperComponent>{children}</WrapperComponent>
    }

    switch(type) {
        case PARAGRAPH_TYPE.L:
            return getContent(ParagraphStyleL);
        case PARAGRAPH_TYPE.S:
            return getContent(ParagraphStyleS);
        case PARAGRAPH_TYPE.M:
        default:
            return getContent(ParagraphStyleM);

    }
}

const CommonStyles = css`
    transition: color ${GLOABL_CONFIG.THEME_TOGGLE_DURATION};
`

const ParagraphStyleL = styled.p`
    ${CommonStyles}
    font-size: 3rem;
    margin: 0;
    color: ${props => props.theme.colors.typography.secondary};
    ${media.md`
        font-size: 2rem;
    `}
`

const ParagraphStyleM = styled.p`
    ${CommonStyles}
    font-size: 2rem;
    margin: 0;
    color: ${props => props.theme.colors.typography.tertiary};
    ${media.md`
        font-size: 1.8rem;
    `}
`

const ParagraphStyleS = styled.p`
    ${CommonStyles}
    font-size: 1.5rem;
    margin: 0;
    color: ${props => props.theme.colors.typography.tertiary};
    ${media.md`
        font-size: 1.4rem;
    `}
`

export default Paragraph;