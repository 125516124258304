import React, { ReactNode } from "react";
import { PageLayoutStyled } from "./PageLayout.styled.ts";

type PageLayoutProps = {
    children: ReactNode, 
    id?: string,
    isHighlight?: boolean,
    backgroundColor?: string,
}

const PageLayout = ({children, isHighlight, id, backgroundColor}: PageLayoutProps) => (
    <PageLayoutStyled id={id} isHighlight={isHighlight} backgroundColor={backgroundColor}>{children}</PageLayoutStyled>
)

export default PageLayout;