import { IconDefinition, faBriefcase, faHouse, faCodeBranch, faCode, faAddressCard } from '@fortawesome/free-solid-svg-icons'

export type RouteType = {
    id: string,
    name: string,
    route: string,
    icon?: IconDefinition,
}

export const ROUTES: Record<string, RouteType> = {
    HOME: {
        id: 'home',
        name: 'Me',
        route: '/#home',
        icon: faHouse,
    },
    EXPERIENCES: {
        id: 'experiences',
        name: 'Experiences',
        route: '/#experiences',
        icon: faBriefcase,
    },
    PROJECTS: {
        id: 'projects',
        name: 'Projects',
        route: '/#projects',
        icon: faCodeBranch,
    }, 
    SKILLS: {
        id: 'skills',
        name: 'Skills',
        route: '/#skills',
        icon: faCode,
    }, 
    CONTACT: {
        id: 'contact',
        name: 'Contact',
        route: '/#contact',
        icon: faAddressCard,
    }
}

export const navbarItems = [
    ROUTES.HOME,
    ROUTES.SKILLS,
    ROUTES.EXPERIENCES,
    ROUTES.PROJECTS,
    ROUTES.CONTACT,
];