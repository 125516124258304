import React from 'react'

import { AppWrapper, GlobalStyle } from './App.styled.js';
import AppRouter from './routes/AppRouter.tsx';
import { CustomThemeProvider } from './themes/CustomThemeContext.tsx';
import { UseDeviceProvider } from './hooks/useDevice.tsx';

const App = () => (
    <UseDeviceProvider>
        <CustomThemeProvider>
            <AppWrapper>
                <GlobalStyle />
                <AppRouter />
            </AppWrapper>
        </CustomThemeProvider>
    </UseDeviceProvider>
)

export default App;