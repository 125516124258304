import styled from "styled-components";
import { media } from "../../statics/Breakpoints.ts";

export const SkillsContainer = styled.div`
    margin: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SkillsHeader = styled.div``;

export const SkillsCategoriesContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
    margin-top: 5rem;

    ${media.sm`
        row-gap: 3rem;
        margin-top: 5rem;
    `}
`;

export const SkillsCategoryWrapper = styled.div`
    display: flex;
    column-gap: 2rem;

    ${media.sm`
        flex-direction: column;
        align-items: flex-start;
    `}
`;

export const SkillsCategoryHeader = styled.div`
    flex: 1;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    border-color: ${props => props.theme.colors.theme.secondary};
    border-style: solid;
    border-width: 0;
    border-right-width: 2px;

    ${media.sm`
        flex-direction: column;
        align-items: flex-start;
        border-right-width: 0;
        padding-right: 0;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom-width: 2px;
    `}
`;

export const SkillsCategoryItemsContainer = styled.ul`
    flex: 3;
    display: flex;
    column-gap: 1rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    ${media.sm`
        row-gap: 0.5rem;
        column-gap: 0.5rem;
    `}
`;

export const SkillsCategoryItemWrapper = styled.li`
    /* &::marker {
        color: red;
        list-style-position: inside;
        font-size: 3rem;
    } */

    list-style: none;
`;