import styled from "styled-components";
import { media } from "../../statics/Breakpoints.ts";

export const ProjectsContainer = styled.div`
    margin: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ProjectsHeader = styled.div``;

export const ProjectsListContainer = styled.div`
margin-top: 5rem;
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
`;

export const ProjectItemWrapper = styled.div`
    display: flex;
    column-gap: 4rem;

    border-color: ${props => props.theme.colors.theme.primary};
    border-style: solid;
    border-width: 0;

    ${media.md`
        flex-direction: column;
        row-gap: 2rem;
        border-bottom-width: 2px;
        padding-bottom: 2rem;
    `}
`;

export const ProjectItemImageWrapper = styled.div`
    flex: 1;

    ${media.md`
        flex: unset;
        height: 25rem;
    `}
    

    img {
        width: 100%;
        /* border-radius: 2rem; */
        object-fit: contain;
        /* box-shadow: ${props => props.theme.shadow.m}; */

        ${media.md`
            width: 100%;
            height: 100%;
            /* border-radius: 1rem; */
        `}
    }
`;

export const ProjectItemContentWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;

export const ProjectsToolsWrapper = styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5rem;
    column-gap: 1rem;
`