import React, { createContext, useContext, ReactNode, useState, useCallback, useEffect } from 'react';
import { BREAKPOINTS, SCREEN_SIZE } from '../statics/Breakpoints.ts';
import { getScreenSize } from '../utils/DeviceHelper.ts';

type DeviceContextType = {
    device: SCREEN_SIZE,
    isScreenLessThan: (SCREEN_SIZE) => boolean,
}

const defaultDeviceContext: DeviceContextType = {
    device: SCREEN_SIZE.xxl,
    isScreenLessThan: () => false,
}

const UseDeviceContext = createContext<DeviceContextType>(defaultDeviceContext);

export const useDevice = () => useContext(UseDeviceContext);

export const UseDeviceProvider = ({ children} : {children: ReactNode}) => {
    const [device, setDevice] = useState<SCREEN_SIZE>(getScreenSize());
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        const onResize = () => {
            setScreenWidth(window.innerWidth);
            setDevice(getScreenSize());
        }

        const onResizeListener = window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResizeListener!);
        }
    }, [])

    const isScreenLessThan = useCallback((targetDevice: SCREEN_SIZE): boolean => {
        if(screenWidth > BREAKPOINTS[targetDevice]) {
            return false;
        }
        return true;
    }, [screenWidth])

    return (
        <UseDeviceContext.Provider value={{ device, isScreenLessThan }}>
            {children}
        </UseDeviceContext.Provider>
    )
}