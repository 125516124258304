import React, { ElementType, ReactNode } from "react";
import styled, { css } from "styled-components";
import { GLOABL_CONFIG } from "../../statics/GlobalConfig.ts";

export enum ANCHOR_TYPE {
    M = 'M',
    S = 'S',
}

export type AnchorTagProps = {
    children: ReactNode,
    isDangerous?: boolean,
    href?: string,
    type?: ANCHOR_TYPE,
}

const AnchorTag = (props: AnchorTagProps) => {
    const { children, isDangerous, type, href } = props;

    const getContent = (WrapperComponent: ElementType) => {
        if(isDangerous) {
            return <WrapperComponent href={href} dangerouslySetInnerHTML={{__html: children}} rel="noopener noreferrer external" target="_blank"/>
        }
        return <WrapperComponent href={href} rel="noopener noreferrer external" target="_blank">{children}</WrapperComponent>
    }

    switch(type) {
        case ANCHOR_TYPE.S:
            return getContent(AnchorTagStyleS);
        case ANCHOR_TYPE.M:
        default:
            return getContent(AnchorTagStyleM);

    }
}

const CommonStyles = css`
    transition: color ${GLOABL_CONFIG.THEME_TOGGLE_DURATION};
`

const AnchorTagStyleM = styled.a`
    ${CommonStyles}
    font-size: 2rem;
    margin: 0;
    color: ${props => props.theme.colors.typography.tertiary};
`

const AnchorTagStyleS = styled.a`
    ${CommonStyles}
    font-size: 1.5rem;
    margin: 0;
    color: ${props => props.theme.colors.typography.tertiary};
`

export default AnchorTag;