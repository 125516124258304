import React, { ElementType, ReactNode } from "react";
import styled, { css } from "styled-components";
import { GLOABL_CONFIG } from "../../statics/GlobalConfig.ts";
import { media } from "../../statics/Breakpoints.ts";

export enum HEADER_TYPE {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
}

export type HeaderProps = {
    children?: ReactNode,
    type?: HEADER_TYPE,
    isDangerous?: boolean,
}

const Header = (props: HeaderProps) => {
    const { type, children, isDangerous } = props;

    const getContent = (WrapperComponent: ElementType) => {
        if(isDangerous) {
            return <WrapperComponent dangerouslySetInnerHTML={{__html: children}}/>
        }
        return <WrapperComponent>{children}</WrapperComponent>
    }

    switch(type) {
        case HEADER_TYPE.H1:
            return getContent(HeaderH1)
        case HEADER_TYPE.H2:
            return getContent(HeaderH2)
        case HEADER_TYPE.H3:
            return getContent(HeaderH3)
            case HEADER_TYPE.H5:
                return getContent(HeaderH5)
        case HEADER_TYPE.H6:
            return getContent(HeaderH6)
        default:
        case HEADER_TYPE.H4:
            return getContent(HeaderH4)
    }
}

const CommonStyles = css`
    transition: color ${GLOABL_CONFIG.THEME_TOGGLE_DURATION};
`

const HeaderH1 = styled.h1`
    ${CommonStyles}
    font-size: 10rem;
    margin: 0;
    color: ${props => props.theme.colors.typography.primary};

    ${media.md`
        font-size: 8rem;
    `}
`

const HeaderH2 = styled.h2`
    ${CommonStyles}
    font-size: 8rem;
    margin: 0;
    color: ${props => props.theme.colors.typography.primary};

    ${media.md`
        font-size: 6rem;
    `}
`

const HeaderH3 = styled.h3`
    ${CommonStyles}
    font-size: 6rem;
    margin: 0;
    color: ${props => props.theme.colors.typography.primary};

    ${media.md`
        font-size: 4rem;
    `}
`

const HeaderH4 = styled.h4`
    ${CommonStyles}
    font-size: 4rem;
    margin: 0;
    color: ${props => props.theme.colors.typography.secondary};

    ${media.md`
        font-size: 3rem;
    `}
`

const HeaderH5 = styled.h5`
    ${CommonStyles}
    font-size: 2rem;
    margin: 0;
    color: ${props => props.theme.colors.typography.secondary};

    ${media.md`
        font-size: 1.5rem;
    `}
`

const HeaderH6 = styled.h6`
    ${CommonStyles}
    font-size: 1.5rem;
    margin: 0;
    color: ${props => props.theme.colors.typography.secondary};

    ${media.md`
        font-size: 1.2rem;
    `}
`

export default Header;