import { css } from "styled-components"

export enum SCREEN_SIZE {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl',
    xxl = 'xxl',
}
  
export const BREAKPOINTS: Record<SCREEN_SIZE, number> = {
    [SCREEN_SIZE.xs]: 400, // for small screen mobile
    [SCREEN_SIZE.sm]: 640, // for mobile screen
    [SCREEN_SIZE.md]: 768, // for tablets
    [SCREEN_SIZE.lg]: 1280, // for laptops
    [SCREEN_SIZE.xl]: 1440, // for desktop / monitors
    [SCREEN_SIZE.xxl]: 1920, // for big screens
}

export const media = {
    xs: (...args) => css`
      @media (max-width: ${BREAKPOINTS.xs}px) {
        ${css(...args)};
      }
    `,
    sm: (...args) => css`
      @media (max-width: ${BREAKPOINTS.sm}px) {
        ${css(...args)};
      }
    `,
    md: (...args) => css`
      @media (max-width: ${BREAKPOINTS.md}px) {
        ${css(...args)};
      }
    `,
    lg: (...args) => css`
      @media (max-width: ${BREAKPOINTS.lg}px) {
        ${css(...args)};
      }
    `,
    xl: (...args) => css`
      @media (max-width: ${BREAKPOINTS.xl}px) {
        ${css(...args)};
      }
    `,
    xxl: (...args) => css`
      @media (max-width: ${BREAKPOINTS.xxl}px) {
        ${css(...args)};
      }
    `,
  }