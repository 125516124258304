import React, { ElementType, ReactNode } from "react";
import styled, { css } from "styled-components";
import { GLOABL_CONFIG } from "../../statics/GlobalConfig.ts";
import { media } from "../../statics/Breakpoints.ts";

export enum LABEL_TYPE {
    L = 'L',
    M = 'M',
    S = 'S',
}

export type LabelProps = {
    children?: ReactNode,
    isDangerous?: boolean,
    type?: LABEL_TYPE,
}

const Label = (props: LabelProps) => {
    const { children, isDangerous, type } = props;

    const getContent = (WrapperComponent: ElementType) => {
        if(isDangerous) {
            return <WrapperComponent dangerouslySetInnerHTML={{__html: children}}/>
        }
        return <WrapperComponent>{children}</WrapperComponent>
    }

    switch(type) {
        case LABEL_TYPE.L:
            return getContent(LabelStyleL);
        case LABEL_TYPE.S:
            return getContent(LabelStyleS);
        case LABEL_TYPE.M:
        default:
            return getContent(LabelStyleM);

    }
}

const CommonStyles = css`
    transition: color ${GLOABL_CONFIG.THEME_TOGGLE_DURATION};
`

const LabelStyleL = styled.span`
    ${CommonStyles}
    font-size: 3rem;
    margin: 0;
    color: ${props => props.theme.colors.typography.secondary};
    ${media.md`
        font-size: 2rem;
    `}
`

const LabelStyleM = styled.span`
    ${CommonStyles}
    font-size: 2rem;
    margin: 0;
    color: ${props => props.theme.colors.typography.secondary};
    ${media.md`
        font-size: 1.8rem;
    `}
`

const LabelStyleS = styled.span`
    ${CommonStyles}
    font-size: 1.5rem;
    margin: 0;
    color: ${props => props.theme.colors.typography.tertiary};
    ${media.md`
        font-size: 1.2rem;
    `}
`

export default Label;