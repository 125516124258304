import React from "react";
import { ExperienceInfoCompanyLogoContainer, ExperienceInfoCompanyNameAnchor, ExperienceInfoContainer, ExperienceItemContainer, ExperienceItemWrapper, ExperiencePositionsContainer, ExperiencesContainer, ExperiencesHeaderContainer, PositionItemWrapper, PositionsTechStackContainer } from "./ExperiencesPage.styled.ts";
import { ExperiencesStatics } from "../../statics/ContentStatics.ts";
import Header, { HEADER_TYPE } from "../../components/typography/Header.tsx";
import { ExperienceItemsType, ExperiencePositionsType } from "../../types/Experience.types.ts";
import { Chip, LABEL_TYPE, Label, PARAGRAPH_TYPE, Paragraph } from "../../components/index.ts";
import { getYearsMonthsBetweenDates, toDisplayMonthYear } from "../../utils/DateTimeUtils.ts";

const ExperiencesPage = () => {

    const getHeaderView = () => (
        <ExperiencesHeaderContainer>
            <Header type={HEADER_TYPE.H4}>{ExperiencesStatics.header}</Header>
        </ExperiencesHeaderContainer>
    )

    const getTechStackItem = (techStackItem: string) => (
        <Chip key={techStackItem}>{techStackItem}</Chip>
    )

    const getPositionItem = (positionItem: ExperiencePositionsType) => (
        <PositionItemWrapper key={positionItem.title}>
            <Header type={HEADER_TYPE.H5}>{positionItem.title}</Header>
            <Label type={LABEL_TYPE.M}>{`${toDisplayMonthYear(positionItem.startDate)} - ${positionItem.endDate ? toDisplayMonthYear(positionItem.endDate) : 'present'}`}</Label>
            <Paragraph type={PARAGRAPH_TYPE.S} isDangerous>{positionItem.description}</Paragraph>
            <PositionsTechStackContainer>
                {positionItem.techStack.map(getTechStackItem)}
            </PositionsTechStackContainer>
        </PositionItemWrapper>
    )

    const getCompanyLogo = (experienceItem: ExperienceItemsType) => (
        <ExperienceInfoCompanyLogoContainer >
            <img src={experienceItem.companyLogo} alt={experienceItem.comapnyName} />
        </ExperienceInfoCompanyLogoContainer>
    )

    const getCompanyName = (experienceItem: ExperienceItemsType) => {
        if(experienceItem.linkdinUrl) {
            return (
                <ExperienceInfoCompanyNameAnchor href={experienceItem.linkdinUrl} rel="noreferrer noopener nofollow" target="_blank">
                    <Header type={HEADER_TYPE.H5}>{experienceItem.comapnyName}</Header>
                </ExperienceInfoCompanyNameAnchor>
            )
        }
        return <Header type={HEADER_TYPE.H5}>{experienceItem.comapnyName}</Header>
    }

    const getExperienceItem = (experienceItem: ExperienceItemsType) => (
        <ExperienceItemWrapper key={experienceItem.id}>
            <ExperienceInfoContainer>
                {getCompanyLogo(experienceItem)}
                {getCompanyName(experienceItem)}
                <Label type={LABEL_TYPE.M}>{getYearsMonthsBetweenDates(experienceItem.startDate!, experienceItem.endDate ? experienceItem.endDate : new Date())}</Label>
                <Label type={LABEL_TYPE.S}>{experienceItem.jobType}</Label>
            </ExperienceInfoContainer>
            <ExperiencePositionsContainer>
                {experienceItem.positions.map(getPositionItem)}
            </ExperiencePositionsContainer>
        </ExperienceItemWrapper>
    )

    const getExperienceView = () => (
        <ExperienceItemContainer>
            {ExperiencesStatics.items.map(getExperienceItem)}
        </ExperienceItemContainer>
    )

    return (
        <ExperiencesContainer>
            {getHeaderView()}
            {getExperienceView()}
        </ExperiencesContainer>
    )
}

export default ExperiencesPage;