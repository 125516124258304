import React from "react";
import { HEADER_TYPE, Header, LABEL_TYPE, Label, PageLayout } from "../../components/index.ts";
import { SkillsCategoriesContainer, SkillsCategoryHeader, SkillsCategoryItemWrapper, SkillsCategoryItemsContainer, SkillsCategoryWrapper, SkillsContainer, SkillsHeader } from "./Skills.styled.ts";
import { SkillsStatics } from "../../statics/ContentStatics.ts";
import { SkillCategoryType } from "../../types/Skills.types.ts";

const Skills = () => {

    const getHeaderView = () => (
        <SkillsHeader>
            <Header type={HEADER_TYPE.H4}>{SkillsStatics.header}</Header>
        </SkillsHeader>
    )

    const getSkillsItem = (skillItem: string, i: number) => (
        <>
            {i>0 && <Label key={`seperator-${skillItem}`}>|</Label>}
            <SkillsCategoryItemWrapper key={skillItem}>
                <Label type={LABEL_TYPE.M}>{skillItem}</Label>
            </SkillsCategoryItemWrapper>
        </>
    )

    const getSkillsCategoryItem = (categoryData: SkillCategoryType) => (
        <SkillsCategoryWrapper key={categoryData.id}>
            <SkillsCategoryHeader>
                <Header type={HEADER_TYPE.H5}>{categoryData.name}</Header>
            </SkillsCategoryHeader>
            <SkillsCategoryItemsContainer>
                {categoryData.items.map(getSkillsItem)}
            </SkillsCategoryItemsContainer>
        </SkillsCategoryWrapper>
    )

    const getSkillsView = () => (
        <SkillsCategoriesContainer>
            {SkillsStatics.categories.map(getSkillsCategoryItem)}
        </SkillsCategoriesContainer>
    )

    return (
        // <PageLayout isHighlight>
            <SkillsContainer>
                {getHeaderView()}
                {getSkillsView()}
            </SkillsContainer>
        // </PageLayout>
    )
}

export default Skills;