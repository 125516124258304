import React from "react";

import { AboutContainer, AboutContentContainer, AboutImageContainer } from "./About.styled.js";
import { AboutStatics } from "../../statics/ContentStatics.ts";
import { HEADER_TYPE, Header, Paragraph } from "../../components/index.ts";

const About = () => {

    const getContentView = () => ( 
        <AboutContentContainer>
            <Header isDangerous type={HEADER_TYPE.H2}>{AboutStatics.header}</Header>
            <Header type={HEADER_TYPE.H5}>{AboutStatics.subHeader}</Header>
            <Paragraph isDangerous>{AboutStatics.content}</Paragraph>
        </AboutContentContainer>
    )

    const getImageView = () => (
        <AboutImageContainer>
            <img src={AboutStatics.img} alt={AboutStatics.header}/>
        </AboutImageContainer>
    )

    return (
        <AboutContainer>
            {getContentView()}
            {getImageView()}
        </AboutContainer>
    )
}

export default About;